import axios from "axios";
import _ from "lodash";
axios.defaults.withCredentials = true;

const baseURL = "https://3pl-api.shipclassy.com";
const baseShipmentURL = "https://3pl-api.shipclassy.com";
// const baseURL = "";
// const baseShipmentURL = "http://localhost:5001";

//#region auth
// register
export const fetchRegister = async (reqBody) => {
  let response;

  try {
    response = await axios.post(`${baseURL}/api/1.0/register`, reqBody);
  } catch (error) {
    console.error("Error fetching register: ", error);
    return;
  }

  return response;
};

// log in
export const fetchLogin = async (reqBody) => {
  let response;

  try {
    response = await axios.post(`${baseURL}/api/1.0/login`, reqBody, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error fetching log in: ", error);
    return;
  }

  return response;
};

// log out
export const fetchLogout = async () => {
  try {
    await axios.get(`${baseURL}/api/1.0/logout`);
  } catch (error) {
    console.error("Error fetching log out: ", error);
    return;
  }
};

// forgot password
export const forgotPassword = async (email) => {
  let response;
  try {
    response = await axios.get(`${baseURL}/api/1.0/forgot_password/${email}`);
  } catch (error) {
    console.error("Error sending forgot password link: ", error);
    return;
  }

  return response;
};

// reset password
export const resetPassword = async (id, reqBody) => {
  const config = {
    headers: {
      Authorization: id,
    },
  };

  console.log("id", id);

  let response;

  try {
    response = await axios.post(
      `${baseURL}/api/1.0/reset_password`,
      reqBody,
      config
    );
  } catch (error) {
    console.error("Error resetting password: ", error);
    return;
  }

  return response;
};

export const fetchUser = async (token) => {
  let response;

  const config = {
    params: {
      token,
    },
  };

  try {
    response = await axios.get(`${baseURL}/api/1.0/fetch_user`, config);
  } catch (error) {
    console.error("Error fetching user: ", error);
    return;
  }

  return response;
};

// current user
export const fetchCurrentUser = async () => {
  let response;

  try {
    response = await axios.get(`${baseURL}/api/1.0/current_user`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error fetching current user: ", error);
    return;
  }

  return response;
};
//#endregion

//#region order
//create order
export const createSingleOrder = async (reqBody, authentication) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  try {
    response = await axios.post(
      `${baseURL}/api/1.0/deliveryWaybill`,
      reqBody,
      config
    );
  } catch (error) {
    console.error("Error creating single order: ", error);
    return error;
  }

  return response;
};

export const createOrder = async (reqBody, authentication) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  try {
    response = await axios.post(
      `${baseURL}/api/1.0/batchDeliveryWaybill`,
      reqBody,
      config
    );
  } catch (error) {
    console.error("Error creating order: ", error);
    return;
  }

  return response;
};

//fetch orders
export const fetchOrders = async (authentication, limit, offset) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
    params: {
      limit,
      offset,
    },
  };

  try {
    response = await axios.get(`${baseURL}/api/1.0/fetchWaybills`, config);
  } catch (error) {
    console.error("Error fetching orders: ", error);
    return;
  }

  return response;
};

// fetch label
export const fetchLabels = async (tracking_num, authentication) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
    params: {
      tracking_num: tracking_num,
    },
  };

  try {
    response = await axios.get(`${baseURL}/api/1.0/fetchLabels`, config);
  } catch (error) {
    console.error("Error fetching labels: ", error);
    return;
  }

  return response;
};

// cancel label
export const cancelLabels = async (tracking_num, authentication, cusID) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  const body = {
    trackingNumbers: [...tracking_num],
    customerCode: cusID,
  };

  try {
    response = await axios.post(
      `${baseURL}/api/1.0/refundWaybill`,
      body,
      config
    );
  } catch (error) {
    console.error("Error canceling labels: ", error);
    return;
  }

  return response;
};

//fetch balance
export const fetchBalance = async (authentication) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  try {
    response = await axios.get(`${baseURL}/api/1.0/fetchBalance`, config);
  } catch (error) {
    console.error("Error fetching balance: ", error);
    return;
  }

  return response;
};

//fetch balance by sub cus ID
export const fetchBalanceBySubCusID = async (authentication) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  try {
    response = await axios.get(
      `${baseURL}/api/1.0/fetchBalanceBySubCusID`,
      config
    );
    // response = await axios.get(
    //   `http://localhost:5002/api/1.0/fetchBalanceBySubCusID`,
    //   config
    // );
  } catch (error) {
    console.error("Error fetching balance: ", error);
    return;
  }

  return response;
};

//update customer balance
export const updateBalance = async (authentication, reqBody) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  try {
    response = await axios.post(
      `${baseURL}/api/1.0/update_balance`,
      reqBody,
      config
    );
    // response = await axios.post(
    //   `http://localhost:5002/api/1.0/update_balance`,
    //   reqBody,
    //   config
    // );
  } catch (error) {
    console.error("Error updating balance: ", error);
    return;
  }

  return response;
};

//update customer balance
export const fetchBalanceLog = async (authentication, customerId) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
    params: {
      customerId,
    },
  };

  try {
    response = await axios.get(`${baseURL}/api/1.0/fetch_balance_log`, config);
    // response = await axios.get(
    //   `http://localhost:5002/api/1.0/fetch_balance_log`,
    //   config
    // );
  } catch (error) {
    console.error("Error fetching user balance: ", error);
    return;
  }

  return response;
};

//adjust customer billing
export const adjustBilling = async (authentication, reqBody) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  try {
    response = await axios.post(
      `${baseURL}/api/1.0/shipmentBillingsAdjustments`,
      reqBody,
      config
    );

    // response = await axios.post(
    //   `http://localhost:5002/api/1.0/shipmentBillingsAdjustments`,
    //   reqBody,
    //   config
    // );
  } catch (error) {
    console.error("Error adjusting billing: ", error);
    return;
  }

  return response;
};

//export order
export const exportOrders = async (authentication, date_from, date_to) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
    params: {
      date_from,
      date_to,
    },
  };

  try {
    response = await axios.get(`${baseURL}/api/1.0/exportWaybills`, config);
  } catch (error) {
    console.error("Error exporting orders: ", error);
    return;
  }

  return response;
};

const _sortCarriers = (shippings) => {
  let rtnRates = {};
  shippings.forEach((shp) => {
    if (rtnRates[shp.carrier_id]) {
      rtnRates[shp.carrier_id].push(shp);
    } else {
      rtnRates[shp.carrier_id] = [shp];
    }
  });

  return rtnRates;
};

//fetch rates
export const fetchRates = async (body, isBatch = false, errorHandler) => {
  let rates = null;

  try {
    let response = null;

    if (isBatch) {
      response = await axios.post(
        `${baseShipmentURL}/api/1.0/batch_shipping_rates`,
        body
      );
    } else {
      response = await axios.post(
        `${baseShipmentURL}/api/1.0/shipping_rates`,
        body
      );
    }

    if (!_.isEmpty(response.data) && !_.isEmpty(response.data.shippings)) {
      let shippings = response.data.shippings;
      if (isBatch) {
        rates = shippings;
      } else {
        if (shippings[0].error && errorHandler) {
          errorHandler(shippings[0].error);
        } else {
          rates = _sortCarriers(shippings[0]);
        }
      }
    }
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }

    console.error("Error fetch rates: ", error);
  }

  return rates;
};

//fetch invoice
export const fetchInvoice = async (
  authentication,
  date_from,
  date_to,
  customer_id
) => {
  let response;

  const config = {
    headers: {
      Authorization: authentication,
    },
    params: {
      date_from,
      date_to,
      customer_id,
    },
  };

  try {
    response = await axios.get(`${baseShipmentURL}/api/1.0/invoice`, config);
  } catch (error) {
    console.error("Error fetching invoice: ", error);
    return;
  }

  return response;
};

// fetch all users
export const fetchAllUser = async (authentication, cusID) => {
  let userData = [];
  const config = {
    headers: {
      Authorization: authentication,
      cusID: cusID ? cusID : null,
    },
  };

  try {
    const response = await axios.get(
      `${baseShipmentURL}/api/1.0/all_user`,
      config
    );

    // const response = await axios.get(
    //   `http://localhost:5001/api/1.0/all_user`,
    //   config
    // );
    userData = response.data;
  } catch (error) {
    console.error("Error fetching all user data: ", error);
    return;
  }

  return userData;
};

// activate user
export const activateUser = async (authentication, userInfo, newCusID) => {
  const config = {
    headers: {
      Authorization: authentication,
      cusID: newCusID ? newCusID : null,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${baseShipmentURL}/api/1.0/activate_user`,
      userInfo,
      config
    );
  } catch (error) {
    console.error("Error activating user status: ", error);
    return;
  }

  return response;
};

// deactivate user
export const deactivateUser = async (authentication, userInfo) => {
  const config = {
    headers: {
      Authorization: authentication,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${baseShipmentURL}/api/1.0/inactivate_user`,
      userInfo,
      config
    );
  } catch (error) {
    console.error("Error activating user status: ", error);
    return;
  }

  return response;
};
